<template>
  <div class="relative w-full">
    <div class="w-full text-center italic text-xs my-1">
      This block is marked as HIDDEN, and is purely for calculating SEO score.
    </div>
    <div class="opacity-50 markdown-render space-y-2 px-4 pt-1 pb-3">
      <h2 v-if="content.title">
        {{ content.title }}
      </h2>
      <div
        v-if="content.content"
        v-html="content.content"
      />
    </div>
  </div>
</template>
<script>
export default {
  friendlyName: 'Hidden Block',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content
    }
  }
}
</script>
